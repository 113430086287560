import { Box, LinearProgress } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import { useState, useEffect } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import UserUploadDialog from "./UserUploadDialog";
import UserAuth from "../Auth/UserAuth";
import {useMsal, useIsAuthenticated } from "@azure/msal-react";
import SignOutMessage from "../Auth/SignOutMessage";

interface Measurement {
  filename: string;
  filepath: string;
  port: number;
}

const Home = () => {
  const [measurementList, setMeasurementList] = useState<Measurement[]>([]);
  const [selectedMeasurement, setSelectedMeasurement] = useState<Measurement>();
  const [showLoader, setShowLoader] = useState(false);
  const serverUrl = "https://robolyzeronline.southindia.cloudapp.azure.com:3011";

  // set state to retrigger the get_measurement_files endpoint
  // whenver a user uploads their specific file
  const [triggerFiles, setTriggerFiles] = useState<boolean>(false);

  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();

  async function postJSON(data: Measurement) {
    setShowLoader(true);
    fetch(
      `${serverUrl}/measurements/run_robolyzer_online?input_file=${selectedMeasurement?.filename}&path=${selectedMeasurement?.filepath}&port=${selectedMeasurement?.port}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        setShowLoader(false);
        response
          .json()
          .then((result) => {
            console.log(result);
            window.open(
              "http://robolyzeronline.southindia.cloudapp.azure.com:" +
                data.port +
                "/",
              "_self",
              "noopener, noreferrer"
            );
          })
          .catch(() => {
            setShowLoader(false);
          });
      })
      .catch(() => {
        setShowLoader(false);
      });
    setTimeout(() => {
      window.open(
        "http://robolyzeronline.southindia.cloudapp.azure.com:" +
          data.port +
          "/",
        "_self",
        "noopener, noreferrer"
      );
    }, 10000);
  }

  useEffect(() => {
    if(isAuthenticated) {
      console.log("authenticated")
      setShowLoader(true);
      fetch(`${serverUrl}/get_measurement_files`)
        .then((response: any) => {
          setShowLoader(false);
          response.json().then((res: any) => {
            const measurementList = [...res];
            setMeasurementList(measurementList);
          });
        })
        .catch((err: string) => {
          setShowLoader(false);
        });
    }
  }, [triggerFiles,isAuthenticated]);

  const onSelectedMeasurementChanged = (value: string) => {
    for (let i = 0; i < measurementList.length; i++) {
      if (measurementList[i].filename === value) {
        setSelectedMeasurement(measurementList[i]);
      }
    }
  };

  const proceed = () => {
    const data: any = {
      fileName: selectedMeasurement?.filename,
      filePath: selectedMeasurement?.filepath,
      port: selectedMeasurement?.port,
    };
    postJSON(data);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" color="secondary">
          <Toolbar>
              <Typography variant="h4" component="div" sx={{ flexGrow: 1 }} justifyContent="center">
                Robolyzer Online 
                <span style={{fontWeight:"bold",fontSize:"14px", marginLeft:"16px", marginTop:"-2px", padding:"8px", backgroundColor:"#e29b17", borderRadius:"1.5em"}}>
                  AOS v0.27
                </span>
              </Typography>

            <Box display="flex" flexDirection="row" gap={4}>
              <Button
                variant="contained"
                color="success"
                sx={{ fontSize: "14px" }}
                disabled={!isAuthenticated}
                onClick={() => {window.open("https://robolyzeronline.southindia.cloudapp.azure.com/robolyzer_online_feature_overview.html","_blank")}}

              >
                <Box display="flex" gap={1}>
                  About
                  <OpenInNewIcon />
                </Box>
              </Button>
              <Box sx={isAuthenticated?{display:"block"}:{display:"none"}}>
                <UserAuth/>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        {showLoader && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="success" />
          </Box>
        )}
        <Box display="flex" flexDirection="row" p={2}>
          <Typography variant="h5">
            {accounts[0]?`Hello, ${accounts[0].name}`: accounts[0]}
          </Typography>
        </Box>
        <FormControl sx={{ mt: 10, ml: "38%", width: "25%" }}>
          <InputLabel
            className="filterLabelOverflow"
            id="select-measurement-label"
            sx={isAuthenticated?{display:"block"}:{display:"none"}}
          >
            {"Select Measurement"}
          </InputLabel>

          <Select
            id="select-measurement"
            data-testid="measurement-dropdown"
            inputProps={{ "data-testid": "measurement-dropdown-input" }}
            labelId="select-measurement-label"
            label="Select Measurement"
            style={{ marginTop: "6px" }}
            onChange={(event: SelectChangeEvent) => {
              onSelectedMeasurementChanged(event.target.value);
            }}
            disabled={!isAuthenticated}
            sx={isAuthenticated?{display:"block"}:{display:"none"}}
          >
            {measurementList.map((measurement, index) => {
              return (
                <MenuItem
                  key={measurement.filename}
                  value={measurement.filename}
                >
                  {measurement.filename}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={4}
          gap={2}
          sx={{ mt: 2 }}
        >
        {!isAuthenticated?(<SignOutMessage/>):<></>}
          <UserUploadDialog
            serverUrl={serverUrl}
            triggerFiles={triggerFiles}
            setTriggerFiles={setTriggerFiles}
            isAuthenticated={isAuthenticated}
          />
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={proceed}
            disabled={!selectedMeasurement}
            sx={isAuthenticated?{display:"block"}:{display:"none"}}
          >
            <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
              Proceed
              <ArrowForwardIosIcon />
            </Box>
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default Home;
